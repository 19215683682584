import React from 'react'
import Container from './container'
import styles from './hero.module.css'

function Hero ({ children, palette, hideNext }) {
  return (
    <div className={`${styles.root} ${styles[palette]}`}>
      <Container size='large' class={styles.container}>
        <h1 className={`${styles.headline}`}>
          {children}
        </h1>
      </Container>
    </div>
  )
}

export default Hero
