import React from 'react'
import Container from './container'
import styles from './locations-grid.module.css'
import BlockText from './block-text'
import { Image } from './Image'

function LocationsGrid ({ locations }) {
  const highlightLocations = []
  const worldLocations = []

  locations.edges.map(location => {
    if (location.node.section.indexOf('highlight') > -1) {
      highlightLocations.push(location.node)
    }

    if (location.node.section.indexOf('world') > -1) {
      worldLocations.push(location.node)
    }
  })
  return (
    <section className={styles.locations}>
      <Container size='large' class={styles.container}>
        <div className={styles.locationsGrid}>
          <div className={styles.locationsTitle}>Offices</div>
          {highlightLocations && highlightLocations.map(location => (
            <div key={location._id} className={styles.locationCard}>
              {location.mainImage &&
            (
              <Image imgObj={location.mainImage} props={{width: 392, height: 694, alt: location.name}} className={styles.locationCardImage} />
            )}
              <div className={styles.locationCardText}>
                <h3>{location.name}</h3>
                <BlockText blocks={location._rawAddress} />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default LocationsGrid
