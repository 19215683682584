import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import styles from './contact-us.module.css'
import ContactForm from '../components/contact-form'
import LocationsGrid from '../components/locations-grid'

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(title: { eq: "Contact Us" }) {
      title
      _rawBody
      metaTitle
      metaDescription
      metaKeywords
    }

    locations: allSanityLocation(sort: {order: ASC, fields: customOrder}) {
      edges {
        node {
          name
          _rawAddress
          section
          _id
          mainImage {
            asset {
              _id
              url
            }
          }
        }
      }
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page
  const locations = data.locations

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout palette='red' hideContactBar>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO title={page.metaTitle || page.title || 'No title'} description={page.metaDescription} keywords={page.metaKeywords} />

      <Hero>
        {page.title}
      </Hero>

      <section className={styles.feedbackFormSection}>
        <ContactForm />
      </section>

      <LocationsGrid locations={locations} />

      {/* <Locations locations={locations} body={page._rawBody} /> */}
    </Layout>
  )
}

export default ContactPage
