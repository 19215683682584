import React, { useState } from 'react'
import styles from './contact-form.module.css'
import * as linkStyle from '../styles/components/links.module.css'
import Icon from './icons'
import axios from 'axios'

function ContactForm () {
  const [formFields, setFormFields] = useState({
    name: '',
    email: '',
    topic: '',
    message: ''
  })
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [formError, setFormError] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormFields({
      ...formFields,
      [name]: value
    })
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return re.test(String(email).toLowerCase())
  }

  const handleSubmit = (evt) => {
    evt.preventDefault()

    setFormError('')
    setError(false)
    setSuccess(false)

    if (!formFields.name || !formFields.email || !formFields.message) {
      setFormError('All fields are required.')
      return
    }

    if (!validateEmail(formFields.email)) {
      setFormError('Please enter a valid email address.')
      return
    }

    const action = evt.target.action

    setLoading(true)

    axios.post(action, formFields)
      .then(res => {
        if (res.status.toString().startsWith('4')) {
          setError(true)
          setLoading(false)
        } else {
          setSuccess(true)
          setLoading(false)
        }
      })
      .catch(() => {
        setError(true)
        setLoading(false)
      })
  }

  return (
    <form action='https://getform.io/f/cab20dcb-af18-492d-a1b0-be4b7b16739f' method='POST' onSubmit={handleSubmit} className={styles.root}>
      <label htmlFor='name'>Name*
        <input type='text' id='name' name='name' value={formFields.name} onChange={handleChange} />
      </label>

      <label htmlFor='email'>Email*
        <input type='email' id='email' name='email' value={formFields.email} onChange={handleChange} />
      </label>

      <label htmlFor='topic'>Topic
        <input type='text' id='topic' name='topic' value={formFields.topic} onChange={handleChange} />
      </label>

      <label htmlFor='message'>How can we help you?*
        <textarea id='message' name='message' value={formFields.message} onChange={handleChange} />
      </label>

      <div className={styles.formFooter}>
        <button className={`${linkStyle.linkArrow} ${linkStyle.submit} ${linkStyle.white}`}>Send
          <span>
            <div className={`${linkStyle.leftArrow} ${linkStyle.iconRed}`}>
              <Icon symbol='rightArrowIcon' color='currentColor' />
              <div className={`${linkStyle.rightArrowCircle}`} />
            </div>
            <div className={`${linkStyle.rightArrow} ${linkStyle.iconRed}`}>
              <Icon symbol='rightArrowIcon' color='currentColor' />
            </div>
          </span>
        </button>

        {formError && <span className={styles.errorMessage}>{formError}</span>}
        {success && <span className={styles.successMessage}>Message sent!</span>}
        {error &&
          <span className={styles.errorMessage}>There was an error submitting the form. Please try again.</span>}
      </div>

    </form>
  )
}

export default ContactForm
